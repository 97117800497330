.ConfirmationDialog {
  &-header {
    margin: 0;
  }

  &-message {
    color: var(--color-text-muted);
    font-size: 16px;
    line-height: 1.4;
    margin: 0;
  }

  &-body {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: var(--sm);
    padding: var(--lg) var(--md);
    text-align: center;
  }

  &-slot {
    margin-bottom: calc(-1 * var(--md));
  }

  &-footer {
    border-top: 1px solid var(--color-border-default);
    display: grid;
    gap: var(--sm);
    grid-template-columns: repeat(2, 1fr);
    padding: var(--sm);
  }
}
